




















































































































import {Component, Mixins} from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "@/components/SimpleForm.vue";
import ButtonWithMessage from "./ButtonWithMessage.vue";
import { signUpForm } from "@/assets/scripts/form-fields/main";
import AppApiMixin from "../mixins/AppApi.vue";
import VueRecaptcha from 'vue-recaptcha';
import {agreementAcceptedExtensions} from "@/assets/scripts/utils";
import XNotice from "@/components/hoc/SimpleNotice.vue";
import DocGetterCard
  from "@/components/for-turnovers-and-reports-view/DocGetterCard.vue";
import DocGetterDialog
  from "@/components/for-turnovers-and-reports-view/DocGetterDialog.vue";
import {AxiosResponse} from "axios";
import DocGetterMixin from "@/components/mixins/DocGetter.vue";

const { values, attrs, validations, errors } = signUpForm;

@Component({
  components: {
    DocGetterDialog, DocGetterCard, XNotice,
    XForm,
    ButtonWithMessage,
    VueRecaptcha
  },
  mixins: [validationMixin],
  computed: errors,
  validations,
})
class SignUpForm extends Mixins(AppApiMixin, XForm, DocGetterMixin) {
  [x: string]: any;
  values = values;

  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitError = "";
  submitSuccess = "";

  downloadButtonLoading = false; // флаг состояния кнопки скачивания

  siteKey = "";
  recaptchaEnabled = false;

  get noFile() {
    return (this.values['uploadPattern']?.length || 0) <= 0;
  }

  mounted() {
    this.siteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || ''
    this.recaptchaEnabled = (process.env.VUE_APP_RECAPTCHA_ENABLED || '') === "true"
  }

  getAgreementAcceptedExtensions() { return agreementAcceptedExtensions() }
  getAgreementAcceptedExtensionsText() { return 'Доступны форматы: ' + agreementAcceptedExtensions() }

  public onCaptchaExpired() {
    //@ts-ignore
    if (this.recaptchaEnabled) {
      this.$refs.recaptcha.reset()
    }
  }

  public validate() {
    if (this.recaptchaEnabled) {
      this.$refs.recaptcha.execute()
    } else {
      this.submitForm('')
    }
  }

  submitForm(recaptchaToken: string) {
    this.submitError = "";
    this.submitSuccess = "";
    this.useSubmitButtonLoading = true;

    const formData = new FormData;
    for (let key in this.values) {
      if (key == 'uploadPattern') {
        if (this.values[key] && this.values[key][0]) {
          formData.append(key, this.values[key][0]);
        }
      } else {
        formData.append(key, this.values[key]);
      }
    }
    formData.append('recaptchaToken', recaptchaToken);

    this.signUp(formData)
      .then((_) => {
        this.submitSuccess = "Заявка успешно отправлена";
      })
      .catch((e) => {
        this.submitError = e;
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }

  public downloadFile(): void {
    const { downloadPatternRegistrationDocument, downloadDocument } = this;

    this.downloadButtonLoading = true;

    downloadPatternRegistrationDocument()
      .then((response: AxiosResponse) => {
        downloadDocument(response);
      })
      .catch((err: any) => console.log(err))
      .finally(() => {
        this.downloadButtonLoading = false;
      });
  }
}

export default SignUpForm;
